<template>
    <b-container>
        <b-card>
            <b-card-header>
                <h3 class="text-center">{{ $t('fill_online_form_page_title') }}</h3>
            </b-card-header>
            <b-card-body>
                <b-form @submit.prevent="submitForm">
                    <b-col cols="12" lg="12" md="12" xl="12">
                        <!-- partner field -->
                        <b-form-group :label="$t('contracting_partner')" class="mb-4" label-for="contracting_partner">
                            <validation-provider
                                #default="{ errors }"
                                name="contracting partner"
                                rules="required"
                            >
                                <b-form-textarea
                                    id="contracting_partner"
                                    required
                                    rows="3"
                                    v-model="form.contractingPartnerStep1"
                                    :disabled="disableAllInputs"
                                ></b-form-textarea>
                                <small class="text-danger">{{ $t(errors[0]) }}</small>
                            </validation-provider>
                        </b-form-group>

                        <!-- first checkboxes -->
                        <b-form-group :disabled="disableAllInputs">
                            <b-form-checkbox class="mb-2" v-model="form.step1_question1">
                                {{ $t('checkbox_1') }}
                            </b-form-checkbox>
                            <b-form-checkbox class="mb-2" v-model="form.step1_question2">
                                {{ $t('checkbox_2') }}
                            </b-form-checkbox>
                            <b-form-checkbox v-model="form.step1_question3">
                                {{ $t('checkbox_3') }}
                            </b-form-checkbox>
                        </b-form-group>

                        <!-- last name, first name, address fields -->
                        <div class="add_input_container">
                            <div :key="index" class="add_input_container my-5" v-for="(additionalField, index) in form.additionalUsers">
                                <b-form-group :label="$t('last_name')" :label-for="'new_last_name_' + index">
                                    <validation-provider #default="{ errors }" name="Last name" rules="required">
                                        <b-form-input :id="'new_last_name_' + index" required v-model="additionalField.lastName" :disabled="disableAllInputs"></b-form-input>
                                        <small class="text-danger">{{ $t(errors[0]) }}</small>
                                    </validation-provider>
                                </b-form-group>
                                <b-form-group :label="$t('first_name')" :label-for="'new_first_name_' + index">
                                    <validation-provider #default="{ errors }" name="First name" rules="required">
                                        <b-form-input :id="'new_first_name_' + index" required v-model="additionalField.firstName" :disabled="disableAllInputs"></b-form-input>
                                        <small class="text-danger">{{ $t(errors[0]) }}</small>
                                    </validation-provider>
                                </b-form-group>
                                <b-form-group :label="$t('actual_address')" :label-for="'new_actual_address_' + index">
                                    <validation-provider #default="{ errors }" name="Actual address" rules="required">
                                        <b-form-input :id="'new_actual_address_' + index" required v-model="additionalField.actualAddress" :disabled="disableAllInputs"></b-form-input>
                                        <small class="text-danger">{{ $t(errors[0]) }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </div>
                        </div>

                        <!-- create new last name, first name, address fields -->

                        <div class="my-3" v-if="!disableAllInputs">
                            <b-button @click="addNewFields" variant="primary">{{ $t('add_new') }}
                            </b-button>
                        </div>

                        <h4>{{ $t('fiduciary_holding_assets') }}</h4>
                        <b-form-group class="mt-2 mb-2" label="Is a third person the beneficial owner of the assets held in the account/securities account?">
                            <b-form-radio-group name="fiduciary-holding" required v-model="form.step1_question4" :disabled="disableAllInputs">
                                <b-form-radio class="mt-1" name="k1" value="no">{{ $t('no') }}</b-form-radio>
                                <br>
                                <b-form-radio class="mt-1" name="k1" value="yes">{{ $t('yes') }}</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>

                        <!-- first radio button check yes -->
                        <template v-if="form.step1_question4 == 'yes'">
                            <fill-online-form-b
                                :disable-all-inputs="disableAllInputs"
                                :beneficialOwner="childForm.beneficialOwner"
                                :step2_question1="childForm.step2_question1"
                                @merge-forms="mergeForms"
                                @update-beneficial-owner="updateBeneficialOwner"
                                @update-step2-question1="updateStep2Question1"
                            />
                        </template>

                        <!-- first radio button check no -->

                        <b-form-group :label="$t('radio_text_2')" class="mt-2 mb-4" v-else-if="form.step1_question4 == 'no'">
                            <b-form-radio-group name="manage-account" required v-model="form.step1_question5" :disabled="disableAllInputs">
                                <b-form-radio class="mt-1" name="d2" value="no">{{ $t('no') }}</b-form-radio>
                                <br>
                                <b-form-radio class="mt-1" name="d2" value="yes">{{ $t('yes') }}</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>

                        <!-- second radio button check no -->

                        <div class="mb-5" v-if="form.step1_question4 == 'no' && form.step1_question5 == 'no'">
                            <b-form-group :label="$t('last_name')" label-for="last_name">
                                <validation-provider
                                    #default="{ errors }"
                                    name="Last name"
                                    rules="required"
                                >
                                    <b-form-input
                                        id="last_name"
                                        required
                                        v-model="form.AccountManagePersonLastName"
                                        :disabled="disableAllInputs"
                                    ></b-form-input>
                                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                                </validation-provider>
                            </b-form-group>
                            <b-form-group :label="$t('first_name')" label-for="first_name">
                                <validation-provider
                                    #default="{ errors }"
                                    name="First name"
                                    rules="required"
                                >
                                    <b-form-input
                                        id="first_name"
                                        required
                                        :disabled="disableAllInputs"
                                        v-model="form.AccountManagePersonFirstName"
                                    ></b-form-input>
                                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                                </validation-provider>
                            </b-form-group>
                            <b-form-group :label="$t('actual_address')" label-for="actual_address">
                                <validation-provider
                                    #default="{ errors }"
                                    name="Actual address"
                                    rules="required"
                                >
                                    <b-form-input
                                        id="actual_address"
                                        required
                                        v-model="form.AccountManagePersonActualAddress"
                                        :disabled="disableAllInputs"
                                    ></b-form-input>
                                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                                </validation-provider>
                            </b-form-group>
                        </div>

                        <b-form-group>
                            <p>
                                {{ $t('register_3a_text1') }}
                            </p>
                            <p>
                                {{ $t('register_3a_text2') }}
                            </p>
                        </b-form-group>


                        <b-row class="d-flex justify-content-between">
                            <b-col lg="6" md="12" xl="5">

                                <b-form-group :label="$t('place')" label-for="place">
                                    <validation-provider
                                        #default="{ errors }"
                                        name="Place"
                                        rules="required"
                                    >
                                        <b-form-input
                                            class="down_inputs"
                                            id="place"
                                            required
                                            v-model="form.place"
                                            :disabled="disableAllInputs"
                                        ></b-form-input>
                                        <small class="text-danger">{{ $t(errors[0]) }}</small>
                                    </validation-provider>
                                </b-form-group>

                                <b-form-group :label="$t('date')" label-for="date">
                                    <validation-provider
                                        #default="{ errors }"
                                        name="Date"
                                        rules="required"
                                    >
                                        <b-form-input
                                            class="down_inputs"
                                            id="date"
                                            required
                                            type="date"
                                            v-model="form.date"
                                            :disabled="disableAllInputs"
                                        ></b-form-input>
                                        <small class="text-danger">{{ $t(errors[0]) }}</small>
                                    </validation-provider>
                                </b-form-group>

                                <!--<b-form-group class="mb-4" label="Place, date:" label-for="place-date">-->
                                <!--<b-form-input id="place-date" placeholder="Place, date" class="down_inputs" v-model="form.placeDate"></b-form-input>-->
                                <!--</b-form-group>-->
                            </b-col>
                            <b-col lg="6" md="12" xl="5">

                                <!--<b-form-group :label="$t('signature')" label-for="signature">-->
                                    <!--<validation-provider-->
                                        <!--#default="{ errors }"-->
                                        <!--name="Signature"-->
                                        <!--rules="required"-->
                                    <!--&gt;-->
                                        <!--<b-form-input-->
                                            <!--class="down_inputs"-->
                                            <!--id="signature"-->
                                            <!--required-->
                                            <!--v-model="form.signature"-->
                                            <!--:disabled="disableAllInputs"-->
                                        <!--&gt;</b-form-input>-->
                                        <!--<small class="text-danger">{{ $t(errors[0]) }}</small>-->
                                    <!--</validation-provider>-->
                                <!--</b-form-group>-->

                                <!--<b-form-group class="mb-4" label="Signatre:" label-for="place-date">-->
                                <!--<b-form-input id="signature" placeholder="Signature(s)" class="down_inputs" v-model="form.signature"></b-form-input>-->
                                <!--</b-form-group>-->
                            </b-col>
                        </b-row>

                        <b-button class="mt-4" type="submit" variant="primary">Submit</b-button>
                    </b-col>
                </b-form>
            </b-card-body>
        </b-card>
    </b-container>
</template>

<script>
  import {ValidationProvider} from "vee-validate";
  import {required} from "@validations";
  import FillOnlineFormB from "./FillOnlineFormB"
  import {
    BAlert,
    BButton,
    BCard,
    BCol,
    BForm,
    BFormCheckbox,
    BFormDatepicker,
    BFormGroup,
    BFormInput,
    BFormRadio,
    BFormRadioGroup,
    BFormSelect,
    BInputGroup,
    BInputGroupPrepend,
    BRow,
  } from "bootstrap-vue";

  export default {
    components: {
      FillOnlineFormB,
      BCard,
      BRow,
      BCol,
      BForm,
      BButton,
      BFormGroup,
      BFormSelect,
      BFormInput,
      BFormCheckbox,
      BFormDatepicker,
      BFormRadio,
      BFormRadioGroup,
      BAlert,
      BInputGroup,
      BInputGroupPrepend,
      ValidationProvider,
    },
    data() {
      return {
        countries: null,
        form: {
          contractingPartnerStep1: null,
          AccountManagePersonFirstName: null,
          AccountManagePersonLastName: null,
          AccountManagePersonActualAddress: null,
          place: null,
          date: null,
          signature: null,
          step1_question1: false,
          step1_question2: false,
          step1_question3: false,
          step1_question4: null,
          step1_question5: null,
          additionalUsers: [
            {
              lastName: null,
              firstName: null,
              actualAddress: null
            }
          ],
        },

        childForm: {
          contractingPartnerStep2: null,
          step2_question1: null,
          beneficialOwner: {
            firstName: null,
            lastName: null,
            dateOfBirth: null,
            nationality: null,
            address: null,
            country: null,
          }
        },

        disableAllInputs: false
      };
    },

    created() {
      this.get_online_form()
    },

    methods: {

      updateStep2Question1(value) {
        this.childForm.step2_question1 = value;
      },

      updateBeneficialOwner(value) {
        this.childForm.beneficialOwner = {...value};
      },

      get_online_form() {
        this.$http
            .post("/online_form")
            .then((res) => {
              if (res.data && res.data.value) {

                if(Object.keys(res.data.value).length !== 0) {
                  this.disableAllInputs = true;
                }

                this.form.contractingPartnerStep1 = res.data.value.contacting_partner_step1;
                this.form.step1_question1 = res.data.value.step1_question1;
                this.form.step1_question2 = res.data.value.step1_question2;
                this.form.step1_question3 = res.data.value.step1_question3;
                this.form.step1_question4 = res.data.value.step1_question4;
                this.form.step1_question5 = res.data.value.step1_question5;

                if(res.data.value.additional_user_manage) {
                  this.form.AccountManagePersonFirstName = res.data.value.additional_user_manage.first_name;
                  this.form.AccountManagePersonLastName = res.data.value.additional_user_manage.last_name;
                  this.form.AccountManagePersonActualAddress = res.data.value.additional_user_manage.address;

                }

                this.childForm.step2_question1 = res.data.value.step2_question1;

                this.form.additionalUsers = res.data.value.additional_users.map(additional_users => ({
                  lastName: additional_users.last_name,
                  firstName: additional_users.first_name,
                  actualAddress: additional_users.address,
                }));


                this.childForm.beneficialOwner.firstName = res.data.value.beneficial_owner.first_name;
                this.childForm.beneficialOwner.lastName = res.data.value.beneficial_owner.last_name;
                this.childForm.beneficialOwner.dateOfBirth = res.data.value.beneficial_owner.date_of_birth;
                this.childForm.beneficialOwner.nationality = res.data.value.beneficial_owner.nationality;
                this.childForm.beneficialOwner.address = res.data.value.beneficial_owner.address;
                this.childForm.beneficialOwner.country = res.data.value.beneficial_owner.country;

                this.form.place = res.data.value.place
                this.form.date = res.data.value.date

              }

            });
      },

      addNewFields() {
        this.form.additionalUsers.push({
          lastName: '',
          firstName: '',
          actualAddress: ''
        });
      },

      submitForm() {
        this.$http
            .post("/online_form/store", this.form)
            .then((res) => {
              if(res.data.status == 200) {
                this.$router.push({ name: 'home' })
              }
              // this.showLoader = false;
              // this.successMessage = true;

              /*this.$toast({
                  component: ToastificationContent,
                  props: {
                      title: "Data received successfully",
                      icon: "SuccessIcon",
                      variant: "success",
                  },
              });*/
            });
      },
      updateForm(updatedForm) {
        // Обновляем данные формы в родительском компоненте
        this.form = updatedForm;
      },

      mergeForms(subForm) {
        this.form = this.mergeObjects({}, this.form, subForm)
      },

      mergeObjects(target, ...sources) {
        for (const source of sources) {
          for (const key in source) {
            if (source[key] instanceof Object) {
              // Recursive merge if both target and source[key] are objects
              if (target[key] instanceof Object) {
                this.mergeObjects(target[key], source[key]);
              } else {
                // Assign source[key] directly if target[key] isn't an object
                target[key] = source[key];
              }
            } else {
              // Assign source[key] directly if it's not an object
              target[key] = source[key];
            }
          }
        }
        return target;
      }
    }

  }
</script>

<style>
    /*#app {*/
    /*padding: 20px;*/
    /*background-color: #f8f9fa;*/
    /*}*/

    /*b-card {*/
    /*max-width: 600px;*/
    /*margin: auto;*/
    /*}*/

    /*b-card-header h3 {*/
    /*margin-bottom: 0;*/
    /*}*/

    /*.mb-2, .mb-4 {*/
    /*margin-bottom: 1rem !important;*/
    /*}*/

    .down_inputs {
        border: none;
        border-bottom: 1px solid;
    }
</style>
